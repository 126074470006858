import { serverMode } from '../../utils/config'
import { TRAINING_MENU } from '../../utils/definitions'
import LandingMenuItem from '../LandingMenuItem/LandingMenuItem'
import './landingMenu.css'

const LandingMenu = () => {
  return (
      <div className='landingMenuContainer'>
        {
            TRAINING_MENU.map(item => (
                <LandingMenuItem title={item.title} url={ serverMode ? item.server : item.local }/>
            ))
        }
    </div>
  )
}

export default LandingMenu