import './App.css';
import { useEffect, useContext, useState } from "react";
import { AuthContext } from "./context/AuthContext";
import { authUrl } from "./utils/authRequest";
import { delay } from "./utils/helper";
import TopBar from './components/TopBar/TopBar';
import Splash from './pages/Splash/Splash';
import Landing from './pages/Landing/Landing';
import { ACTION_TYPES, AUTH_STATUS, REDIRECT_URL } from './utils/definitions';
import { serverMode } from './utils/config';



function App() {
  const [tokenValid, setTokenValid] = useState(false)
  const [authStatus, setAuthStatus] = useState('')
  const { user, dispatch } = useContext(AuthContext);

  const checkToken = async () => {
    try {
      let config = {
        headers: {
          "Content-Type": "application/json",
        },
        withCredentials: "same-origin",
      };
      const res = await authUrl.get("/verifyTokenResponse", config)
      console.log(res)
      delay(1000).then( () => {
        setAuthStatus(AUTH_STATUS.AUTH_AUTHENTICATED)
        delay(1000).then( ()=> {
          setTokenValid(true)
          loadAssociate()
        })
      } )
    } catch (error) {
      console.log(error)
      delay(1000).then( () => {
        setAuthStatus(AUTH_STATUS.AUTH_NOT_AUTHENTICATED)
        delay(1000).then( ()=> 
          window.location.href = serverMode ? REDIRECT_URL.AUTH_PROD : REDIRECT_URL.AUTH_LOCAL
        )
      })
    }
  }

  const loadAssociate = async () => {
    try {
      let config = {
        headers: {
          "Content-Type": "application/json",
        },
        withCredentials: "same-origin",
      };
      const res = await authUrl.get("/authAssociateLogin", config)
      console.log(res)
      dispatch({ type: ACTION_TYPES.SUCCESS, payload: res.data });
    } catch (error) {
      console.log(error)
    }
  }

  useEffect( () => {
    const onPageLoad = () => {
      checkToken()
    };
    if (document.readyState === 'complete') {
        onPageLoad();
    } else {
        window.addEventListener('load', onPageLoad);
        return () => window.removeEventListener('load', onPageLoad);
    }
  }, [])

  return (
    <>
      {(tokenValid && user?.permissions.includes("whitelist")) ? (
        <div className="appContainer">
          <TopBar />
          <Landing />
        </div>
      ) : (
        <Splash authStatus={authStatus}/>
      )}
    </>
  );
}

export default App;
