export const TRAINING_MENU = [
    {
      title: "Manage Class",
      local: '',
      server: ''
    },
    {
      title: "Knowledge Checks",
      local: '',
      server: ''
    },
    {
      title: "Training Operations",
      local: '',
      server: ''
    },
    {
      title: "Training Reports",
      local: '',
      server: ''
    },
  ]
  export const ACTION_TYPES = {
    START: "START",
    SUCCESS: "SUCCESS",
    CHANGE_STATUS: "CHANGE_STATUS",
    FAILURE: "FAILURE",
    LOGOUT: "LOGOUT",
  };

  export const AUTH_STATUS = {
    AUTH_PROCESSING: "Authenticating...",
    AUTH_AUTHENTICATED: "Associate authenticated.",
    AUTH_NOT_AUTHENTICATED: "Associate is not authenticated.",
    AUTH_NOT_AUTHORIZED: "Associate is not authorized."
  }

  export const REDIRECT_URL = {
    BASE_LOCAL: 'http://localhost:3000',
    BASE_PROD: 'https://teamcgs.io',
    AUTH_LOCAL: 'http://localhost:3005/redirect/base',
    AUTH_PROD: 'https://auth.teamcgs.io',
  }